import React, { useContext, useState, useEffect } from "react";
import { searchOutsideIslingtonPeople } from "../api/api";
import AdultsIcon from "../public/images/couple.png";
import { Button, Icon, TextInput, Table, Modal } from "react-materialize";
import ChildrenIcon from "../public/images/children.png";
import postcodes from "./IslingtonPostcodes.json";

export default function ExtraHousehold() {
    // let { isSignedIn, signIn, signOut } = useContext(AuthContext);
    let [people, updatePeople] = useState([]);
    let [searchname, updateSearchname] = useState("");

    useEffect(() => {
        searchOutsideIslingtonPeople().then(updatePeople);
    }, []);
    console.log(people);
    function getByLastname(searchname, mapFunction) {
        if (searchname.length == 0) return [];
        return [
            ...people
                .filter(
                    (p) =>
                        p.lastname
                            .toLowerCase()
                            .includes(searchname.toLowerCase()) ||
                        p.firstname
                            .toLowerCase()
                            .includes(searchname.toLowerCase()) ||
                        p.postcode
                            .toLowerCase()
                            .includes(searchname.toLowerCase())
                )
                .map(mapFunction),
        ];
    }

    let results = getByLastname(searchname, (p) => p);

    return (
        <div>
            <h3>Search Households Outside Islington</h3>
            <h5>By Lastname or Firstname</h5>
            <div className="row" style={{ textAlign: "center" }}>
                <div
                    className="col s12"
                    style={{ display: "flex", justifyContent: "space-around" }}
                >
                    <TextInput
                        value={searchname}
                        onChange={(e) => updateSearchname(e.target.value)}
                        placeholder="Search ..."
                        style={{ fontSize: "1.6em", textAlign: "center" }}
                    ></TextInput>
                </div>
            </div>
            <Table style={{ fontSize: "28px" }}>
                <thead>
                    <tr>
                        <th data-field="lastname">Lastname</th>
                        <th data-field="firstname">Firstname</th>
                        <th data-field="postcode">Postcode</th>
                        <th data-field="adults">
                            Adults <img src={AdultsIcon} height={24}></img>
                        </th>
                        <th data-field="children">
                            Children <img src={ChildrenIcon} height={20}></img>
                        </th>
                        <th data-field="id">ID</th>
                    </tr>
                </thead>
                <tbody>
                    {results.map((result) => (
                        <tr
                            key={
                                result.id || result.firstname + result.lastname
                            }
                        >
                            <td>{result.lastname}</td>
                            <td>{result.firstname}</td>
                            {postcodes.includes(result.postcode) ? (
                                <td>
                                    {result.postcode} <Icon>verified</Icon>
                                </td>
                            ) : (
                                // not islington postcodes   //icon 'check'
                                <td style={{ color: "orange" }}>
                                    {result.postcode}
                                </td>
                            )}
                            {/* Adults icons created by IconMarketPK - Flaticon */}
                            <td>{result.adults}</td>
                            <td>
                                {` ${result.children} {${
                                    result.childrenDetails || ""
                                } }`}{" "}
                            </td>
                            <td>{result.id}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}
