import React from "react";
import { UserContext } from "./auth/AuthContext";
import Container from "./foodform/Container";
// import { BrowserRouter } from "react-router-dom";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
import AssistanceContainer from "./householdAssistance/AssistanceContainer";

export default function App() {
    return (
        <React.StrictMode>
            {/* <BrowserRouter> */}
            <UserContext>
                {/* <Container /> */}
                <AssistanceContainer />
            </UserContext>
            {/* </BrowserRouter> */}
        </React.StrictMode>
    );
}
