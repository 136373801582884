/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.foodbank.protos.Household', null, global);
goog.exportSymbol('proto.foodbank.protos.ListAttendanceRequest', null, global);
goog.exportSymbol('proto.foodbank.protos.ListAttendanceResponse', null, global);
goog.exportSymbol('proto.foodbank.protos.ListHouseholdsRequest', null, global);
goog.exportSymbol('proto.foodbank.protos.ListHouseholdsResponse', null, global);
goog.exportSymbol('proto.foodbank.protos.RegisterAttendanceRequest', null, global);
goog.exportSymbol('proto.foodbank.protos.RegisterAttendanceResponse', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.foodbank.protos.RegisterAttendanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.foodbank.protos.RegisterAttendanceRequest.repeatedFields_, null);
};
goog.inherits(proto.foodbank.protos.RegisterAttendanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.foodbank.protos.RegisterAttendanceRequest.displayName = 'proto.foodbank.protos.RegisterAttendanceRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.foodbank.protos.RegisterAttendanceRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.foodbank.protos.RegisterAttendanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.foodbank.protos.RegisterAttendanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.foodbank.protos.RegisterAttendanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.foodbank.protos.RegisterAttendanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getField(msg, 1),
    strsList: jspb.Message.getRepeatedField(msg, 2)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.foodbank.protos.RegisterAttendanceRequest}
 */
proto.foodbank.protos.RegisterAttendanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.foodbank.protos.RegisterAttendanceRequest;
  return proto.foodbank.protos.RegisterAttendanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.foodbank.protos.RegisterAttendanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.foodbank.protos.RegisterAttendanceRequest}
 */
proto.foodbank.protos.RegisterAttendanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.addStrs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.foodbank.protos.RegisterAttendanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.foodbank.protos.RegisterAttendanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.foodbank.protos.RegisterAttendanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.foodbank.protos.RegisterAttendanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStrsList();
  if (f.length > 0) {
    writer.writeRepeatedInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.foodbank.protos.RegisterAttendanceRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.foodbank.protos.RegisterAttendanceRequest.prototype.setId = function(value) {
  jspb.Message.setField(this, 1, value);
};


proto.foodbank.protos.RegisterAttendanceRequest.prototype.clearId = function() {
  jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.foodbank.protos.RegisterAttendanceRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated int32 strs = 2;
 * @return {!Array.<number>}
 */
proto.foodbank.protos.RegisterAttendanceRequest.prototype.getStrsList = function() {
  return /** @type {!Array.<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array.<number>} value */
proto.foodbank.protos.RegisterAttendanceRequest.prototype.setStrsList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.foodbank.protos.RegisterAttendanceRequest.prototype.addStrs = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


proto.foodbank.protos.RegisterAttendanceRequest.prototype.clearStrsList = function() {
  this.setStrsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.foodbank.protos.RegisterAttendanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.foodbank.protos.RegisterAttendanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.foodbank.protos.RegisterAttendanceResponse.displayName = 'proto.foodbank.protos.RegisterAttendanceResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.foodbank.protos.RegisterAttendanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.foodbank.protos.RegisterAttendanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.foodbank.protos.RegisterAttendanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.foodbank.protos.RegisterAttendanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.foodbank.protos.RegisterAttendanceResponse}
 */
proto.foodbank.protos.RegisterAttendanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.foodbank.protos.RegisterAttendanceResponse;
  return proto.foodbank.protos.RegisterAttendanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.foodbank.protos.RegisterAttendanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.foodbank.protos.RegisterAttendanceResponse}
 */
proto.foodbank.protos.RegisterAttendanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.foodbank.protos.RegisterAttendanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.foodbank.protos.RegisterAttendanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.foodbank.protos.RegisterAttendanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.foodbank.protos.RegisterAttendanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.foodbank.protos.ListAttendanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.foodbank.protos.ListAttendanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.foodbank.protos.ListAttendanceRequest.displayName = 'proto.foodbank.protos.ListAttendanceRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.foodbank.protos.ListAttendanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.foodbank.protos.ListAttendanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.foodbank.protos.ListAttendanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.foodbank.protos.ListAttendanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.foodbank.protos.ListAttendanceRequest}
 */
proto.foodbank.protos.ListAttendanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.foodbank.protos.ListAttendanceRequest;
  return proto.foodbank.protos.ListAttendanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.foodbank.protos.ListAttendanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.foodbank.protos.ListAttendanceRequest}
 */
proto.foodbank.protos.ListAttendanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.foodbank.protos.ListAttendanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.foodbank.protos.ListAttendanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.foodbank.protos.ListAttendanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.foodbank.protos.ListAttendanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.foodbank.protos.ListAttendanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.foodbank.protos.ListAttendanceResponse.repeatedFields_, null);
};
goog.inherits(proto.foodbank.protos.ListAttendanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.foodbank.protos.ListAttendanceResponse.displayName = 'proto.foodbank.protos.ListAttendanceResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.foodbank.protos.ListAttendanceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.foodbank.protos.ListAttendanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.foodbank.protos.ListAttendanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.foodbank.protos.ListAttendanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.foodbank.protos.ListAttendanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: jspb.Message.getRepeatedField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.foodbank.protos.ListAttendanceResponse}
 */
proto.foodbank.protos.ListAttendanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.foodbank.protos.ListAttendanceResponse;
  return proto.foodbank.protos.ListAttendanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.foodbank.protos.ListAttendanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.foodbank.protos.ListAttendanceResponse}
 */
proto.foodbank.protos.ListAttendanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.foodbank.protos.ListAttendanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.foodbank.protos.ListAttendanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.foodbank.protos.ListAttendanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.foodbank.protos.ListAttendanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedInt32(
      1,
      f
    );
  }
};


/**
 * repeated int32 ids = 1;
 * @return {!Array.<number>}
 */
proto.foodbank.protos.ListAttendanceResponse.prototype.getIdsList = function() {
  return /** @type {!Array.<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array.<number>} value */
proto.foodbank.protos.ListAttendanceResponse.prototype.setIdsList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.foodbank.protos.ListAttendanceResponse.prototype.addIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.foodbank.protos.ListAttendanceResponse.prototype.clearIdsList = function() {
  this.setIdsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.foodbank.protos.ListHouseholdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.foodbank.protos.ListHouseholdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.foodbank.protos.ListHouseholdsRequest.displayName = 'proto.foodbank.protos.ListHouseholdsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.foodbank.protos.ListHouseholdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.foodbank.protos.ListHouseholdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.foodbank.protos.ListHouseholdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.foodbank.protos.ListHouseholdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    textFilter: jspb.Message.getField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.foodbank.protos.ListHouseholdsRequest}
 */
proto.foodbank.protos.ListHouseholdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.foodbank.protos.ListHouseholdsRequest;
  return proto.foodbank.protos.ListHouseholdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.foodbank.protos.ListHouseholdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.foodbank.protos.ListHouseholdsRequest}
 */
proto.foodbank.protos.ListHouseholdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.foodbank.protos.ListHouseholdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.foodbank.protos.ListHouseholdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.foodbank.protos.ListHouseholdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.foodbank.protos.ListHouseholdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string text_filter = 1;
 * @return {string}
 */
proto.foodbank.protos.ListHouseholdsRequest.prototype.getTextFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.foodbank.protos.ListHouseholdsRequest.prototype.setTextFilter = function(value) {
  jspb.Message.setField(this, 1, value);
};


proto.foodbank.protos.ListHouseholdsRequest.prototype.clearTextFilter = function() {
  jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.foodbank.protos.ListHouseholdsRequest.prototype.hasTextFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.foodbank.protos.ListHouseholdsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.foodbank.protos.ListHouseholdsResponse.repeatedFields_, null);
};
goog.inherits(proto.foodbank.protos.ListHouseholdsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.foodbank.protos.ListHouseholdsResponse.displayName = 'proto.foodbank.protos.ListHouseholdsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.foodbank.protos.ListHouseholdsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.foodbank.protos.ListHouseholdsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.foodbank.protos.ListHouseholdsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.foodbank.protos.ListHouseholdsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.foodbank.protos.ListHouseholdsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    householdsList: jspb.Message.toObjectList(msg.getHouseholdsList(),
    proto.foodbank.protos.Household.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.foodbank.protos.ListHouseholdsResponse}
 */
proto.foodbank.protos.ListHouseholdsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.foodbank.protos.ListHouseholdsResponse;
  return proto.foodbank.protos.ListHouseholdsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.foodbank.protos.ListHouseholdsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.foodbank.protos.ListHouseholdsResponse}
 */
proto.foodbank.protos.ListHouseholdsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.foodbank.protos.Household;
      reader.readMessage(value,proto.foodbank.protos.Household.deserializeBinaryFromReader);
      msg.addHouseholds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.foodbank.protos.ListHouseholdsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.foodbank.protos.ListHouseholdsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.foodbank.protos.ListHouseholdsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.foodbank.protos.ListHouseholdsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHouseholdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.foodbank.protos.Household.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Household households = 1;
 * @return {!Array.<!proto.foodbank.protos.Household>}
 */
proto.foodbank.protos.ListHouseholdsResponse.prototype.getHouseholdsList = function() {
  return /** @type{!Array.<!proto.foodbank.protos.Household>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.foodbank.protos.Household, 1));
};


/** @param {!Array.<!proto.foodbank.protos.Household>} value */
proto.foodbank.protos.ListHouseholdsResponse.prototype.setHouseholdsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.foodbank.protos.Household=} opt_value
 * @param {number=} opt_index
 * @return {!proto.foodbank.protos.Household}
 */
proto.foodbank.protos.ListHouseholdsResponse.prototype.addHouseholds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.foodbank.protos.Household, opt_index);
};


proto.foodbank.protos.ListHouseholdsResponse.prototype.clearHouseholdsList = function() {
  this.setHouseholdsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.foodbank.protos.Household = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.foodbank.protos.Household, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.foodbank.protos.Household.displayName = 'proto.foodbank.protos.Household';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.foodbank.protos.Household.prototype.toObject = function(opt_includeInstance) {
  return proto.foodbank.protos.Household.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.foodbank.protos.Household} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.foodbank.protos.Household.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getField(msg, 6),
    lastName: jspb.Message.getField(msg, 1),
    firstName: jspb.Message.getField(msg, 2),
    postCode: jspb.Message.getField(msg, 3),
    adults: jspb.Message.getField(msg, 4),
    children: jspb.Message.getField(msg, 5)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.foodbank.protos.Household}
 */
proto.foodbank.protos.Household.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.foodbank.protos.Household;
  return proto.foodbank.protos.Household.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.foodbank.protos.Household} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.foodbank.protos.Household}
 */
proto.foodbank.protos.Household.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostCode(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdults(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChildren(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.foodbank.protos.Household.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.foodbank.protos.Household.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.foodbank.protos.Household} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.foodbank.protos.Household.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 id = 6;
 * @return {number}
 */
proto.foodbank.protos.Household.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.foodbank.protos.Household.prototype.setId = function(value) {
  jspb.Message.setField(this, 6, value);
};


proto.foodbank.protos.Household.prototype.clearId = function() {
  jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.foodbank.protos.Household.prototype.hasId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string last_name = 1;
 * @return {string}
 */
proto.foodbank.protos.Household.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.foodbank.protos.Household.prototype.setLastName = function(value) {
  jspb.Message.setField(this, 1, value);
};


proto.foodbank.protos.Household.prototype.clearLastName = function() {
  jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.foodbank.protos.Household.prototype.hasLastName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.foodbank.protos.Household.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.foodbank.protos.Household.prototype.setFirstName = function(value) {
  jspb.Message.setField(this, 2, value);
};


proto.foodbank.protos.Household.prototype.clearFirstName = function() {
  jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.foodbank.protos.Household.prototype.hasFirstName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string post_code = 3;
 * @return {string}
 */
proto.foodbank.protos.Household.prototype.getPostCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.foodbank.protos.Household.prototype.setPostCode = function(value) {
  jspb.Message.setField(this, 3, value);
};


proto.foodbank.protos.Household.prototype.clearPostCode = function() {
  jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.foodbank.protos.Household.prototype.hasPostCode = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 adults = 4;
 * @return {number}
 */
proto.foodbank.protos.Household.prototype.getAdults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.foodbank.protos.Household.prototype.setAdults = function(value) {
  jspb.Message.setField(this, 4, value);
};


proto.foodbank.protos.Household.prototype.clearAdults = function() {
  jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.foodbank.protos.Household.prototype.hasAdults = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 children = 5;
 * @return {number}
 */
proto.foodbank.protos.Household.prototype.getChildren = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.foodbank.protos.Household.prototype.setChildren = function(value) {
  jspb.Message.setField(this, 5, value);
};


proto.foodbank.protos.Household.prototype.clearChildren = function() {
  jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.foodbank.protos.Household.prototype.hasChildren = function() {
  return jspb.Message.getField(this, 5) != null;
};


goog.object.extend(exports, proto.foodbank.protos);
