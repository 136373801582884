import ReactDOM from "react-dom";
import M, { toast } from "materialize-css";
import {
    Button,
    Card,
    Row,
    Col,
    Icon,
    Checkbox,
    Toast,
    Divider,
} from "react-materialize";
import App from "./App";
import React, { createContext, useContext, useEffect, useState } from "react";
import { func } from "prop-types";

ReactDOM.render(<App />, document.getElementById("root"));

function deepHook([value, updateValue], key, defaultValue) {
    return [
        value[key] != undefined ? value[key] : defaultValue,
        (nv) => {
            var fn;
            if (typeof nv != "function") {
                fn = (ov) => nv;
            } else {
                fn = nv;
            }
            updateValue((ov) => {
                let virtualOldValue =
                    ov[key] != undefined ? ov[key] : defaultValue;
                if (Array.isArray(ov)) {
                    let att = [...ov];
                    att[key] = fn(virtualOldValue);
                    return att;
                }
                return {
                    ...ov,
                    [key]: fn(virtualOldValue),
                };
            });
        },
    ];
}
