import React, { useContext, useState, useEffect } from "react";
import { useChecklistState } from "./hooks";
import { AuthContext } from "../auth/AuthContext";
import FoodCategory from "./FoodCategory";
import { toast } from "materialize-css";
import { Button, Icon, TextInput } from "react-materialize";
import { getCategories, getFoodItems, getPeople, saveOrder } from "../api/api";
import _ from "lodash";

const GROUPING_AND_SORTING = {
    CATEGORY_AND_ROWNUMBER: {
        sortBy: (item) => item.order,
        groupBy: (item) => item.category,
    },
    NO_CATEGORY_AND_ROWNUMBER: {
        sortBy: (item) => "Items",
        groupBy: (item) => item.category,
    },
};

export default function FoodList() {
    let { isSignedIn, signIn, signOut } = useContext(AuthContext);
    let selectedHook = useChecklistState({ checked: false, disabled: false });
    let { clearAll, getItem } = selectedHook;
    let [foodItems, updateFoodItems] = useState([]);
    let [categories, updateCategories] = useState([]);
    let [people, updatePeople] = useState([]);
    let [id, updateId] = useState("");
    let [groupByCategory, setGroupByCategory] = useState(true);

    let foodWithCategories = foodItems.map((item) => {
        let categoryInfo = categories.filter((c) => c.id == item.id)[0] || {};
        return { ...item, ...categoryInfo };
    });
    let itemsByCategory = _.groupBy(foodWithCategories, (item) =>
        groupByCategory ? item.category : "Items"
    );
    useEffect(() => {
        if (isSignedIn) {
            getFoodItems().then(updateFoodItems);
            getPeople().then(updatePeople);
            getCategories().then(updateCategories);
        }
    }, [isSignedIn]);
    function getById(id, mapFunction) {
        return [...people.filter((p) => p.id == id).map(mapFunction), "-"][0];
    }
    return (
        <div>
            <h3>Order</h3>
            <div className="row" style={{ textAlign: "center" }}>
                <div
                    className="col m3 s12"
                    style={{ display: "flex", justifyContent: "space-around" }}
                >
                    <TextInput
                        value={id}
                        onChange={(e) => updateId(e.target.value)}
                        placeholder="Household #"
                        style={{ fontSize: "1.6em", textAlign: "center" }}
                    ></TextInput>
                </div>
                <div className="col m8 s12">
                    <h4>
                        {`Initials: ${getById(id, (p) => p.initials)} `}
                        &nbsp;&nbsp;&nbsp;
                        {`Adults: ${getById(id, (p) => p.adults)} `}
                        &nbsp;&nbsp;&nbsp;
                        {`Children: ${getById(id, (p) => p.children)}`}
                    </h4>
                    <h4></h4>
                </div>
            </div>
            {Object.entries(itemsByCategory).map(([key, value]) => (
                <FoodCategory
                    key={key}
                    category={key}
                    selectedHook={selectedHook}
                    items={value}
                />
            ))}
            <Button
                fab
                style={{ margin: "20px", marginBottom: "90px" }}
                floating={true}
                className="red"
                waves="light"
                icon={<Icon>sort</Icon>}
                onClick={() => setGroupByCategory((v) => !v)}
            ></Button>
            <Button
                large
                fab
                style={{ margin: "10px" }}
                floating={true}
                className="red"
                waves="light"
                icon={<Icon>send</Icon>}
                onClick={() => {
                    saveOrder({
                        items: foodItems.filter(
                            (item) => getItem(item.id).checked
                        ),
                        householdId: id,
                        adults: getById(id, (p) => p.adults),
                        children: getById(id, (p) => p.children),
                    });
                    clearAll();
                    updateId("");
                    toast({ html: "Saved", displayLength: 1000 });
                }}
            ></Button>
        </div>
    );
}
