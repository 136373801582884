import React, { useContext } from "react";
import { Button, Icon, Navbar, NavItem } from "react-materialize";
import { AuthContext } from "../auth/AuthContext";
import HouseHold from "./HouseHold";

export default function Container() {
    let { isSignedIn, signIn, signOut } = useContext(AuthContext);
    const goToExtraList = () => {};
    return (
        <>
            <Navbar
                alignLinks="right"
                brand={
                    <a className="brand-logo" href="#">
                        Food Bank Assistance
                    </a>
                }
                centerLogo
                menuIcon={<Icon>menu</Icon>}
                options={{
                    draggable: true,
                    edge: "left",
                    inDuration: 250,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 200,
                    preventScrolling: true,
                }}
            >
                {isSignedIn ? (
                    <NavItem onClick={signOut}>Sign Out</NavItem>
                ) : (
                    <NavItem onClick={signIn}>Sign In</NavItem>
                )}
                <NavItem onClick={goToExtraList}>Extra List</NavItem>
            </Navbar>
            <div className="container">
                <HouseHold />
            </div>
        </>
    );
}
