import React from "react";
import { UserContext } from "./auth/AuthContext";
import Container from "./foodform/Container";
import AssistanceContainer from "./householdAssistance/AssistanceContainer";

export default function App() {
    return (
        <UserContext>
            {/* <Container /> */}
            <AssistanceContainer />
        </UserContext>
    );
}
