/**
 * @fileoverview gRPC-Web generated client stub for foodbank.protos
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.5.1
// source: src/main/proto/org/shhp/foodbank/protos/Household.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.foodbank = {};
proto.foodbank.protos = require('./Household_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.foodbank.protos.HouseholdServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.foodbank.protos.HouseholdServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.foodbank.protos.ListHouseholdsRequest,
 *   !proto.foodbank.protos.ListHouseholdsResponse>}
 */
const methodDescriptor_HouseholdService_ListHouseholds = new grpc.web.MethodDescriptor(
  '/foodbank.protos.HouseholdService/ListHouseholds',
  grpc.web.MethodType.UNARY,
  proto.foodbank.protos.ListHouseholdsRequest,
  proto.foodbank.protos.ListHouseholdsResponse,
  /**
   * @param {!proto.foodbank.protos.ListHouseholdsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.foodbank.protos.ListHouseholdsResponse.deserializeBinary
);


/**
 * @param {!proto.foodbank.protos.ListHouseholdsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.foodbank.protos.ListHouseholdsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.foodbank.protos.ListHouseholdsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.foodbank.protos.HouseholdServiceClient.prototype.listHouseholds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/foodbank.protos.HouseholdService/ListHouseholds',
      request,
      metadata || {},
      methodDescriptor_HouseholdService_ListHouseholds,
      callback);
};


/**
 * @param {!proto.foodbank.protos.ListHouseholdsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.foodbank.protos.ListHouseholdsResponse>}
 *     Promise that resolves to the response
 */
proto.foodbank.protos.HouseholdServicePromiseClient.prototype.listHouseholds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/foodbank.protos.HouseholdService/ListHouseholds',
      request,
      metadata || {},
      methodDescriptor_HouseholdService_ListHouseholds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.foodbank.protos.ListOutsideIslingtonHouseholdsRequest,
 *   !proto.foodbank.protos.ListOutsideIslingtonHouseholdsResponse>}
 */
const methodDescriptor_HouseholdService_ListOutsideIslingtonHouseholds = new grpc.web.MethodDescriptor(
  '/foodbank.protos.HouseholdService/ListOutsideIslingtonHouseholds',
  grpc.web.MethodType.UNARY,
  proto.foodbank.protos.ListOutsideIslingtonHouseholdsRequest,
  proto.foodbank.protos.ListOutsideIslingtonHouseholdsResponse,
  /**
   * @param {!proto.foodbank.protos.ListOutsideIslingtonHouseholdsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.foodbank.protos.ListOutsideIslingtonHouseholdsResponse.deserializeBinary
);


/**
 * @param {!proto.foodbank.protos.ListOutsideIslingtonHouseholdsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.foodbank.protos.ListOutsideIslingtonHouseholdsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.foodbank.protos.ListOutsideIslingtonHouseholdsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.foodbank.protos.HouseholdServiceClient.prototype.listOutsideIslingtonHouseholds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/foodbank.protos.HouseholdService/ListOutsideIslingtonHouseholds',
      request,
      metadata || {},
      methodDescriptor_HouseholdService_ListOutsideIslingtonHouseholds,
      callback);
};


/**
 * @param {!proto.foodbank.protos.ListOutsideIslingtonHouseholdsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.foodbank.protos.ListOutsideIslingtonHouseholdsResponse>}
 *     Promise that resolves to the response
 */
proto.foodbank.protos.HouseholdServicePromiseClient.prototype.listOutsideIslingtonHouseholds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/foodbank.protos.HouseholdService/ListOutsideIslingtonHouseholds',
      request,
      metadata || {},
      methodDescriptor_HouseholdService_ListOutsideIslingtonHouseholds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.foodbank.protos.ListAttendanceRequest,
 *   !proto.foodbank.protos.ListAttendanceResponse>}
 */
const methodDescriptor_HouseholdService_ListAttendance = new grpc.web.MethodDescriptor(
  '/foodbank.protos.HouseholdService/ListAttendance',
  grpc.web.MethodType.UNARY,
  proto.foodbank.protos.ListAttendanceRequest,
  proto.foodbank.protos.ListAttendanceResponse,
  /**
   * @param {!proto.foodbank.protos.ListAttendanceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.foodbank.protos.ListAttendanceResponse.deserializeBinary
);


/**
 * @param {!proto.foodbank.protos.ListAttendanceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.foodbank.protos.ListAttendanceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.foodbank.protos.ListAttendanceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.foodbank.protos.HouseholdServiceClient.prototype.listAttendance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/foodbank.protos.HouseholdService/ListAttendance',
      request,
      metadata || {},
      methodDescriptor_HouseholdService_ListAttendance,
      callback);
};


/**
 * @param {!proto.foodbank.protos.ListAttendanceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.foodbank.protos.ListAttendanceResponse>}
 *     Promise that resolves to the response
 */
proto.foodbank.protos.HouseholdServicePromiseClient.prototype.listAttendance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/foodbank.protos.HouseholdService/ListAttendance',
      request,
      metadata || {},
      methodDescriptor_HouseholdService_ListAttendance);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.foodbank.protos.RegisterAttendanceRequest,
 *   !proto.foodbank.protos.RegisterAttendanceResponse>}
 */
const methodDescriptor_HouseholdService_RegisterAttendance = new grpc.web.MethodDescriptor(
  '/foodbank.protos.HouseholdService/RegisterAttendance',
  grpc.web.MethodType.UNARY,
  proto.foodbank.protos.RegisterAttendanceRequest,
  proto.foodbank.protos.RegisterAttendanceResponse,
  /**
   * @param {!proto.foodbank.protos.RegisterAttendanceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.foodbank.protos.RegisterAttendanceResponse.deserializeBinary
);


/**
 * @param {!proto.foodbank.protos.RegisterAttendanceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.foodbank.protos.RegisterAttendanceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.foodbank.protos.RegisterAttendanceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.foodbank.protos.HouseholdServiceClient.prototype.registerAttendance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/foodbank.protos.HouseholdService/RegisterAttendance',
      request,
      metadata || {},
      methodDescriptor_HouseholdService_RegisterAttendance,
      callback);
};


/**
 * @param {!proto.foodbank.protos.RegisterAttendanceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.foodbank.protos.RegisterAttendanceResponse>}
 *     Promise that resolves to the response
 */
proto.foodbank.protos.HouseholdServicePromiseClient.prototype.registerAttendance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/foodbank.protos.HouseholdService/RegisterAttendance',
      request,
      metadata || {},
      methodDescriptor_HouseholdService_RegisterAttendance);
};


module.exports = proto.foodbank.protos;

