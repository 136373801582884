import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext([null, () => {}]);

// Client ID and API key from the Developer Console
const CLIENT_ID =
    // "839430631155-03vg3o3oahcdfnlb91hq00f20t9h232v.apps.googleusercontent.com";
    // "839430631155-k5k50r19i5c7kp3t2jig81ngqdip50bi.apps.googleusercontent.com";
    "839430631155-7192vst23bq1trojo8ldmmulc2n2e36a.apps.googleusercontent.com";

// Array of API discovery doc URLs for APIs used by the quickstart
const DISCOVERY_DOCS = [
    "https://sheets.googleapis.com/$discovery/rest?version=v4",
];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES = "https://www.googleapis.com/auth/spreadsheets";

export function UserContext({ children }) {
    let authHook = useAuthState();
    let { isSignedIn, updateIsSignedIn } = authHook;
    useEffect(() => {
        // return;
        (() => {
            // google.accounts.id.initialize({
            //     client_id: CLIENT_ID,
            //     callback: handleCredentialResponse,
            //     // native_callback: handleCredentialResponse,
            //     //     // native_callback: resolve,
            //     use_fedcm_for_prompt: true,
            //     auto_select: false,
            // });
            // googlAuthSetCallback(() => {
            //     console.log("Logged in");
            // });
            // google.accounts.id.prompt();
        })();
        // return;
        // new Promise((resolve, reject) => {
        //     gapi.load("client:auth2", resolve);
        // })
        //     .then(() => {
        //         console.log("oh");
        //         console.log(gapi.client);
        //         return gapi.client.init({
        //             //   apiKey: API_KEY,
        //             clientId: CLIENT_ID,
        //             discoveryDocs: DISCOVERY_DOCS,
        //             scope: SCOPES,
        //         });
        //     })
        //     .then(() => {
        //         console.log("yeah");
        //         // Listen for sign-in state changes.
        //         gapi.auth2.getAuthInstance().isSignedIn.listen((v) => {
        //             updateIsSignedIn(v);
        //         });
        //         // // Handle the initial sign-in state.
        //         updateIsSignedIn(gapi.auth2.getAuthInstance().isSignedIn.get());
        //     })
        //     .catch((ex) => {
        //         console.error(ex);
        //     });
    }, []);
    return (
        <AuthContext.Provider value={authHook}>{children}</AuthContext.Provider>
    );
}

export function useAuthState() {
    let [isSignedIn, updateIsSignedIn] = useState(false);
    let signIn = () => {
        if (!isSignedIn) {
            // gapi.auth2.getAuthInstance().signIn();
            new Promise((resolve) => {
                google.accounts.id.prompt();
            })
                .then((creds) => {
                    console.log(creds);
                })
                .catch((err) => {
                    console.error(err);
                });
            // }).then((resp) => {
            //     updateIsSignedIn(true);
            //     console.log(resp);
            // (() => {
            //     new Promise((resolve, reject) =>
            //         gapi.load("client", resolve)
            //     )
            //         .then(() => {
            //             return gapi.client.init({
            //                 apiKey: CLIENT_ID,
            //                 discoveryDocs: [DISCOVERY_DOCS],
            //                 scopes: SCOPES,
            //             });
            //         })
            //         .then((a) => {
            //             console.log(a);
            //         })
            //         .catch((e) => {
            //             console.log(e);
            //         });
            // })();
            // });
        }
    };
    let signOut = () => {
        if (isSignedIn) {
            // gapi.auth2.getAuthInstance().signOut();
            google.accounts.id.disableAutoSelect();
        }
    };
    return { isSignedIn, updateIsSignedIn, signIn, signOut };
}
