import { HouseholdServicePromiseClient } from "./gen/sample_grpc_web_pb";
import {
    ListHouseholdsRequest,
    ListHouseholdsResponse,
    ListAttendanceRequest,
    RegisterAttendanceRequest,
} from "./gen/sample_pb";
import { Message, inherits } from "google-protobuf";

import _ from "lodash";

// const SPREADSHEET_ID = "1PXSRn2jYv4-u4O8Ty7yw2Kwf9Slm98geE3IzLDpWPvw";
const SPREADSHEET_ID = "1o7i-p2C9l6eUimgj4tzhMXwsz8lS15U0rWW6Ix-zYNw";
const MAIN_SHEET_TRANSPOSED = "Copy of FoodRVert";
const ASSISTANCE_SHEET = "Comp_assistance";
const client = new HouseholdServicePromiseClient("http://icpcintec.com");

export function getFoodItems() {
    return new Promise((resolve, reject) => {
        gapi.client.sheets.spreadsheets.values
            .get({
                spreadsheetId: SPREADSHEET_ID,
                range: `'${MAIN_SHEET_TRANSPOSED}'!F2:BF3`,
                majorDimension: "COLUMNS",
            })
            .then(
                function (response) {
                    var range = response.result;
                    if (range.values.length > 0) {
                        let ret = [];
                        for (let idx in range.values) {
                            let column = range.values[idx];
                            if (ret.filter((e) => e.id == column[0]).length > 0)
                                continue;
                            if (column[0] == null) continue;
                            ret.push({
                                id: column[0],
                                position: 6 + Number(idx),
                                name: column[1],
                                // category: "1",
                                order: idx,
                            });
                        }
                        resolve(ret);
                    } else {
                        reject(Error("No data found"));
                    }
                },
                function (response) {
                    reject(response.result.error);
                }
            );
    });
}

export function getPeople() {
    return new Promise((resolve, reject) => {
        gapi.client.sheets.spreadsheets.values
            .get({
                spreadsheetId: SPREADSHEET_ID,
                range: "'Household Details'!A4:O629",
            })
            .then(
                function (response) {
                    var range = response.result;
                    if (range.values.length > 0) {
                        let ret = [];
                        for (let idx in range.values) {
                            let row = range.values[idx];
                            if (ret.filter((e) => e.id == row[0]).length > 0)
                                continue;
                            if (row[0] == null) continue;
                            let family = {
                                id: row[0],
                                initials: row[1],
                                firstname: row[3],
                                lastname: row[4],
                                postcode: row[5],
                                adults: row[6],
                                children: row[7],
                                childrenDetails: row[8],
                            };
                            // redact schools
                            if (row[7].trim() == "school") {
                                family.initials = "##";
                            }
                            ret.push(family);
                        }
                        resolve(ret);
                    } else {
                        reject(Error("No data found"));
                    }
                },
                function (response) {
                    reject(response.result.error);
                }
            );
    });
}

export function getCategories() {
    return new Promise((resolve, reject) => {
        gapi.client.sheets.spreadsheets.values
            .get({
                spreadsheetId: SPREADSHEET_ID,
                range: "'Products Category'!A2:E56",
            })
            .then(
                function (response) {
                    var range = response.result;
                    if (range.values.length > 0) {
                        let ret = [];
                        for (let idx in range.values) {
                            let row = range.values[idx];
                            if ((row[0] || "").trim() == "") continue;
                            let product = {
                                id: row[0],
                                category: row[3],
                            };
                            ret.push(product);
                        }
                        resolve(ret);
                    } else {
                        reject(Error("No data found"));
                    }
                },
                function (response) {
                    reject(response.result.error);
                }
            );
    });
}

/***
 * Save Order Version 2
 */
export function saveOrder(order) {
    function getNextRow() {
        return gapi.client.sheets.spreadsheets.values
            .get({
                spreadsheetId: SPREADSHEET_ID,
                range: `'${MAIN_SHEET_TRANSPOSED}'!B4:B5000`,
                majorDimension: "COLUMNS",
            })
            .then((resp) => {
                var range = resp.result.values[0];
                console.log(range);
                return String(
                    (range.indexOf("") == -1
                        ? range.length
                        : range.indexOf("")) + 4
                );
            });
    }
    let values = Array(70).fill("");
    values[0] = new Date().toLocaleDateString("en-US");
    values[1] = order.householdId;
    values[2] = order.adults;
    values[3] = order.children;
    for (let item of order.items) {
        values[
            item.position -
                2 /* Is one based and we start writign from column B (2nd) */
        ] = 1;
    }
    return getNextRow().then((row) => {
        console.log(row);
        console.log(values);
        return gapi.client.sheets.spreadsheets.values.update({
            spreadsheetId: SPREADSHEET_ID,
            valueInputOption: "USER_ENTERED",
            range: `'${MAIN_SHEET_TRANSPOSED}'!B${row}:ZZ${row}`,
            // requestBody: {
            // majorDimension: "COLUMNS",
            values: [values],
            // },
        });
    });
}

export function searchPeople() {
    return client
        .listHouseholds(new ListHouseholdsRequest())
        .then((r) => {
            return r.getHouseholdsList().map((h) => ({
                id: h.getId(),
                firstname: h.getFirstName(),
                lastname: h.getLastName(),
                postcode: h.getPostCode(),
                adults: h.getAdults(),
                children: h.getChildren(),
            }));
        })
        .then((r) => {
            console.log(r);
            return r;
        })
        .catch((e) => {
            console.log(e);
        });
}

export function registerAttendance(householdId) {
    return client.registerAttendance(
        new RegisterAttendanceRequest({ id: householdId })
    );
}

export function getAttendants() {
    return client
        .listAttendance(new ListAttendanceRequest())
        .then((r) => r.getIdsList());
}

Message.prototype.fromJsObject = function fromObject(plain_obj) {
    let proto_obj = this;
    for (const field_name in plain_obj) {
        let field_value = plain_obj[field_name];
        // console.log(plain_obj);
        let set_method_name = `set${_.upperFirst(_.camelCase(field_name))}`;
        let add_method_name = `add${_.upperFirst(_.camelCase(field_name))}`;
        let get_method_name = `get${_.upperFirst(_.camelCase(field_name))}`;
        // let old_field_value = proto_obj[get_method_name](); //TODO: could use this to detect field type?
        // console.log(field_name, set_method_name, old_field_value, field_value);
        if (_.isArray(field_value)) {
            if (!proto_obj[add_method_name])
                throw `The field ${field_name} does not exist or is not repeated`;
            for (let e of field_value) {
                if (_.isObject(e)) {
                    proto_obj[add_method_name]().fromJsObject(e);
                } else {
                    proto_obj[add_method_name](e);
                }
            }
        } else if (_.isObject()) {
            if (!proto_obj[get_method_name])
                throw `The field ${field_name} does not exist`;
            proto_obj[set_method_name](
                proto_obj[get_method_name]().fromJsObject(field_value)
            );
        } else if (proto_obj[set_method_name]) {
            if (!proto_obj[get_method_name])
                throw `The field ${field_name} does not exist`;
            proto_obj[set_method_name](field_value);
        }
    }
    return proto_obj;
};
Message.initialize = ((oldInitialize) =>
    function (self, obj) {
        oldInitialize(...arguments);
        if (_.isObject(obj)) {
            self.fromJsObject(_.omit(obj, 1));
        }
    })(Message.initialize);
