import React, { useState } from "react";
import { Card } from "react-materialize";

export default function FoodItem({ foodItem, selectedHook }) {
    let { getItemHook } = selectedHook;
    let [state, updateState] = getItemHook(foodItem.id);
    const toggleCheckbox = () => {
        if (state.disabled) return;
        updateState((state) => ({ ...state, checked: !state.checked }));
    };
    return (
        <div className="col m6 s12">
            <div
                onClick={toggleCheckbox}
                style={{
                    // backgroundColor: state.disabled ? "lightgray" : "white",
                    margin: "0.3em",
                }}
            >
                <input
                    type="checkbox"
                    checked={state.checked}
                    disabled={state.disabled}
                    onChange={toggleCheckbox}
                />
                <span style={{ fontSize: "1.4em" }}>
                    {foodItem.name == "Rice" ||
                    foodItem.name == "Salt" ||
                    foodItem.name == "Washing-up liquid" ||
                    foodItem.name == "New potatoes"
                        ? "--------------" + foodItem.name + "--------------"
                        : foodItem.name}
                    <span style={{ color: "red" }}>
                        {state.disabled ? " (Not Available)" : ""}
                    </span>
                </span>
            </div>
        </div>
    );
}
