import React, { useContext, useState } from "react";
import { Button, Icon, Navbar, NavItem } from "react-materialize";
import { AuthContext } from "../auth/AuthContext";
import HouseHold from "./HouseHold";
import ExtraHousehold from "./ExtraHousehold";
// import { Routes, Route, NavLink } from "react-router-dom";

export default function Container() {
    let { isSignedIn, signIn, signOut } = useContext(AuthContext);
    let [screen, updateScreen] = useState("main");
    const goToExtraList = (name) => {
        updateScreen(name);
    };
    return (
        <>
            <Navbar
                alignLinks="right"
                brand={
                    <a className="brand-logo" href="#">
                        Food Bank Assistance
                    </a>
                }
                centerLogo
                menuIcon={<Icon>menu</Icon>}
                options={{
                    draggable: true,
                    edge: "left",
                    inDuration: 250,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 200,
                    preventScrolling: true,
                }}
            >
                {isSignedIn ? (
                    <NavItem onClick={signOut}>Sign Out</NavItem>
                ) : (
                    <NavItem onClick={signIn}>Sign In</NavItem>
                )}
                <NavItem onClick={() => updateScreen("main")}>
                    Main List
                </NavItem>
                <NavItem
                    className="active"
                    onClick={() => updateScreen("extra")}
                >
                    Extra List
                </NavItem>
            </Navbar>
            <div className="container">
                {screen == "main" ? <HouseHold /> : <ExtraHousehold />}
            </div>
        </>
    );
}
