import React, { useState } from "react";

export const useChecklistState = (defaultValue) => {
  let [state, setState] = useState({});
  let getItem = (id) => {
    return state[id] == undefined ? defaultValue : state[id];
  };
  let getItemHook = (id) => {
    return [
      getItem(id),
      (fn) =>
        setState((ov) => ({
          ...ov,
          [id]: asUpdaterFunction(fn)(
            ov[id] == undefined ? defaultValue : ov[id]
          ),
        })),
    ];
  };
  let clearAll = () => setState({});
  return { getItemHook, getItem, clearAll, state };
};

function asUpdaterFunction(nv) {
  if (typeof nv != "function") {
    return (ov) => nv;
  } else {
    return nv;
  }
}
