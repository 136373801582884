import React from "react";
import { Divider } from "react-materialize";
import FoodItem from "./FoodItem";

export default function FoodCategory({ items, category, selectedHook }) {
  return (
    <div className="row">
      <Divider />
      <h4>{category}</h4>
      {items.map((it, idx) => {
        return (
          <FoodItem
            key={it.name}
            foodItem={it}
            selectedHook={selectedHook}
          ></FoodItem>
        );
      })}
    </div>
  );
}
