import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../auth/AuthContext";
import { Button, Icon, TextInput, Table, Modal } from "react-materialize";
import { searchPeople, registerAttendance, getAttendants } from "../api/api";
import AdultsIcon from "../public/images/couple.png";
import ChildrenIcon from "../public/images/children.png";
import postcodes from "./IslingtonPostcodes.json";

export default function HouseHold() {
    // let { isSignedIn, signIn, signOut } = useContext(AuthContext);
    let [people, updatePeople] = useState([]);
    let [searchname, updateSearchname] = useState("");
    let [checkedHouseholds, updateCheckedHouseholds] = useState([]);
    let { modal, showModal } = useAttendanceModal();

    useEffect(() => {
        searchPeople().then(updatePeople);
        getAttendants().then(updateCheckedHouseholds);
    }, []);

    function getByLastname(searchname, mapFunction) {
        if (searchname.length == 0) return [];
        return [
            ...people
                .filter(
                    (p) =>
                        p.lastname
                            .toLowerCase()
                            .includes(searchname.toLowerCase()) ||
                        p.firstname
                            .toLowerCase()
                            .includes(searchname.toLowerCase())
                )
                .map(mapFunction),
        ];
    }

    let results = getByLastname(searchname, (p) => p);

    function markAttendance(id) {
        Promise.all([registerAttendance(id), showModal(id)])
            .then(() => updateSearchname(""))
            .then(() => getAttendants().then(updateCheckedHouseholds));
    }

    return (
        <div>
            <h3>Search Household</h3>
            <h5>By Lastname or Firstname</h5>
            <div className="row" style={{ textAlign: "center" }}>
                <div
                    className="col s12"
                    style={{ display: "flex", justifyContent: "space-around" }}
                >
                    <TextInput
                        value={searchname}
                        onChange={(e) => updateSearchname(e.target.value)}
                        placeholder="Search ..."
                        style={{ fontSize: "1.6em", textAlign: "center" }}
                    ></TextInput>
                </div>
            </div>
            {modal}
            <Table style={{ fontSize: "28px" }}>
                <thead>
                    <tr>
                        <th data-field="lastname">Lastname</th>
                        <th data-field="firstname">Firstname</th>
                        <th data-field="postcode">Postcode</th>
                        <th data-field="adults">
                            Adults <img src={AdultsIcon} height={24}></img>
                        </th>
                        <th data-field="children">
                            Children <img src={ChildrenIcon} height={20}></img>
                        </th>
                        <th data-field="actions">Actions</th>
                        <th data-field="id">ID</th>
                    </tr>
                </thead>
                <tbody>
                    {results.map((result) => (
                        <tr key={result.id}>
                            <td>{result.lastname}</td>
                            <td>{result.firstname}</td>
                            {postcodes.includes(result.postcode) ? (
                                <td>
                                    {result.postcode} <Icon>verified</Icon>
                                </td>
                            ) : (
                                // not islington postcodes   //icon 'check'
                                <td style={{ color: "orange" }}>
                                    {result.postcode}
                                </td>
                            )}
                            {/* Adults icons created by IconMarketPK - Flaticon */}
                            <td>{result.adults}</td>
                            <td>
                                {` ${result.children} {${
                                    result.childrenDetails || ""
                                } }`}{" "}
                            </td>
                            <td>
                                {!checkedHouseholds.includes(result.id) ? (
                                    <Button
                                        onClick={() =>
                                            markAttendance(result.id)
                                        }
                                    >
                                        Attended
                                    </Button>
                                ) : (
                                    `Marked as attended`
                                )}
                            </td>

                            <td
                                style={{
                                    color: checkedHouseholds.includes(result.id)
                                        ? "lightGreen"
                                        : "darkGray",
                                }}
                            >
                                {result.id}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

function useAttendanceModal() {
    const [show, setShow] = useState(false);
    const [id, setId] = useState(-1);
    const [closeFn, setCloseFn] = useState(() => {});
    const modal = (
        <Modal
            actions={[
                <Button
                    flat
                    modal="close"
                    node="button"
                    onClick={closeFn}
                    waves="green"
                >
                    Close
                </Button>,
            ]}
            bottomSheet={false}
            fixedFooter={false}
            header="Success!"
            open={show}
            options={{
                dismissible: true,
                endingTop: "20%",
                inDuration: 250,
                onCloseEnd: null,
                onCloseStart: null,
                onOpenEnd: null,
                onOpenStart: null,
                opacity: 0.5,
                outDuration: 250,
                preventScrolling: true,
                startingTop: "4%",
                borderRadius: "20px",
            }}
        >
            <h2 style={{ textAlign: "center" }}>{id}</h2>
            <p style={{ textAlign: "center" }}>
                Household # marked as attended
            </p>
        </Modal>
    );
    return {
        modal,
        showModal: (id) => {
            return new Promise((resolve) => {
                setId(id);
                setShow(true);
                setCloseFn(() => () => {
                    setShow(false);
                    resolve();
                });
            });
        },
    };
}
